@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSans-Regular.ttf");
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.login-container {
  background-color: #B3DFFF;
  min-height: 100vh;
  padding-left: 150px;
  padding-top: 30px;
}

.login-header {
  width: 50%;
}

.ulysses-navbar-personal {
  display: flex;
  align-items: center;
  border: 2px solid #EFF3F4;
}

.ulysses-navbar-personal-container {
  display: flex;

}

.ulysses-navbar-personal-item {
  padding: 12px 13px;
  font-weight: bold;
  color: black;
  text-decoration: none;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
  font-size: 13px;
}

.ulysses-navbar-personal-item:hover,
.ulysses-navbar-personal-item.active {
  cursor: pointer;
  background-color: #F7F7F7;
  color: #0752C2;
  border-bottom: 1px solid #0752C2;
}

.ulysses-navbar-personal-settings {
  flex: 1;
  padding-right: 16px;
}

.ulysses-navbar {
  background: #B3DFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 120px;
  padding-right: 150px;
  padding-top: 20px;
}

.bg-ulysses {
  background: #40A9FE;
}

.btn-transparent {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  border: 1px solid #000 !important;
  padding: 10px 20px !important;
}

.btn-transparent:disabled {
  cursor: not-allowed !important;
}

.bold {
  font-weight: bold !important;
}

.h1-header {
  font-size: 3.5rem;
}

.industries-select-container {
  display: flex;
  flex-wrap: wrap;

}

.industries-select-box {
  border: 2px solid #EFF3F4;
  border-radius: 8px;
  height: 100px;
  width: 130px;
  display: flex;
  margin: 5px 8px;
  cursor: pointer;
  padding: 12px;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.industries-select-box:hover {
  border: 2px solid #40A9FE;
}

.industries-select-box.selected {
  background: #1C9BF0;
  color: white;
}

.industries-select-box>i {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
}

.industries-select-text {
  align-self: flex-end;
  font-size: 14px;
  font-weight: bold;
}

.ulysses-search-container {
  display: flex;
}

.ulysses-search-sidebar {
  width: 280px;

}

.ulysses-search-content {
  flex: 1;
  background: #F7F7F7;
  padding: 12px;
}

.btn-secondary {
  background: white !important;
  color: #1A1A1A !important;
  border-radius: 4px !important;
  border: 1px solid #CACACC !important;
}

.btn-secondary:hover {
  color: #0C6EFD !important;
  border: 1px solid #0C6EFD !important;
}

.search-buttons-container {
  padding: 10px 0;
  display: flex;
}

.ulysses-search-table {
  max-height: 450px !important;
  overflow: scroll;
}

.ulysses-search-table th {
  font-size: 12px;
}

.ulysses-search-filter-item {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  padding: 12px 18px;
  align-items: center;
  border-bottom: 1px solid #E3E3E5;
  cursor: pointer;
}

.ulysses-search-filter-item:hover {
  background: #F7F7F7;
  color: #0752C2;
}

.ulysses-search-header {
  font-size: 18px;
  font-weight: bold;
  padding: 16px 18px;
  border-bottom: 1px solid #E3E3E5;
}

.ulysses-primary {
  color: #0752C2;
}

.ulysses-search-pagination {
  font-size: 12px;
  display: flex;
  align-items: center;
  border: 1px solid #E3E3E5;
  padding: 8px 10px;
}

.ulysses-search-pagination button {
  border-radius: 1px;
}

.ulysses-search-pagination select {
  height: 32px;
  font-size: 12px;
  font-weight: bold;
}

.ulysses-search-title {
  font-size: 16px;
  color: #474747;
}

.btn:disabled {
  pointer-events: unset !important;
  cursor: not-allowed !important;
}

.react-tooltip {
  font-size: 12px !important;
}

.save-leads-item {
  background: #F7F7F7 !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.save-leads-title {
  font-size: 15px;
  font-weight: bold;
  padding: 12px 24px;
  border-bottom: 1px solid #E3E3E5;
}

.custom-ng-select {
  font-size: 14px;
  color: #737373;
}

.save-leads-item-content {
  padding: 10px 24px;
  padding-bottom: 28px;
}

.ulysses-sequences-title {
  padding: 16px 16px;
  padding-top: 18px;
  border-bottom: 1px solid #E3E3E5;
}

.ulysses-sequences-container {
  background: #F7F7F7;
  padding: 12px;
}

.ulysses-sequences-body {
  background: white;
  border: 1px solid #E3E3E5;
  border-radius: 6px;
  padding: 10px;
  padding-top: 0;
}

.ulysses-sequences-buttons {
  padding: 10px 0;
}

.ulysses-sequences-table {}

.modal-new-sequence-body {
  padding: 40px;
}

.modal-new-sequence-item {
  border: 1px solid #E3E3E5;
  border-radius: 6px;
  width: 240px;
  text-align: center;
  padding: 20px;
  cursor: pointer;
  margin: 8px;
  padding-bottom: 10px;
}

.modal-new-sequence-item:hover {
  background: #F7F7F7;
}

.no-chevron::after {
  display: none !important;
}

.btn-muted {
  color: #737373 !important;
  border: 1px solid #E3E3E5 !important;
}

.ulysses-prebuilt-sidebar {
  width: 270px;
  background: #F7F7F7;
  height: 70vh;
  overflow: scroll;

}

.ulysses-prebuilt-sidebar-item {

  padding: 16px;
  color: #474747;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;

}

.ulysses-prebuilt-sidebar-item:hover,
.ulysses-prebuilt-sidebar-item.active {
  background: #E3E3E5;
}

.ulysses-prebuilt-content {
  flex: 1;
  padding: 32px 48px;
  height: 70vh;
  overflow: scroll;
}

.btn-circle {
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 32px;
  height: 32px;
}

.accordion-button:not(.collapsed) {
  background-color: unset !important;
}

.accordion-button:focus {
  border-color: unset !important;
}

.divider-breadcrumb {
  --bs-breadcrumb-divider: '>';
}

.sequence-detail-container {
  background-color: #F7F7F7 !important;
  padding: 16px;
}

.card-shadow {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border: unset !important;
  border-radius: 3px !important;
}

.pointer {
  cursor: pointer !important;
}

.sequence-detail-item {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.sequence-detail-item:hover {
  background: #F7F7F7;
}

.sequence-list-item {
  display: flex;
  align-items: center;
  border: 1px solid #E3E3E5;
  padding: 12px 20px;
}

.blank-link {
  text-decoration: none;
  color: unset;
}

.notification {
  position: fixed;
  width: 350px;
  top: 60px;
  right: -350px;
  padding: 24px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  z-index: 1050;
  background: white;
}

.notification.active {
  right: 10px;
}

.notification:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.trumbowyg-button-pane {
  background: unset !important;
}

.preview-step-div {
  background: #F7F7F7;
  border: 1px solid #E3E3E5;
  border-radius: 3px;
  padding: 16px;
  height: 335px;
  overflow: scroll;
}

.sequence-nav-item {
  padding: 12px 20px;
  margin: 8px 0;
  cursor: pointer;
  text-decoration: unset;
  font-weight: 600;
  color: black;
}

.sequence-nav-item:hover {
  background: #F7F7F7;
}

.sequence-nav-item.active {
  border-bottom: 1px solid #0C6EFD;
  color: #0C6EFD;
}

.email-sequence-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 12px 20px;
  border: 1px solid #E3E3E5;
  background: white;
}

.email-sequence-item:hover {
  background: #F7F7F7;
}

.email-sequence-item:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: unset;
}

.email-sequence-item:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.email-sequence-contacts-stats {
  display: flex;
  align-items: center;

  border: 1px solid #E3E3E5;
  background: white;
  margin-bottom: 10px;
}

.sequence-contacts-stats-item {
  width: 150px;
  font-size: 12px;
  text-align: center;
  padding: 12px 20px;
  cursor: pointer;
}

.sequence-contacts-stats-item:hover, .sequence-contacts-stats-item.active {
  background: #F7F7F7;
  color: #0C6DFD;
}

.dropdown {
  z-index: 9999 !important;
}

.link-bold {
  font-weight: bold;
  text-decoration: unset;
  color: black;
}

.bg-gray {
  background: #F7F8FC;
}

.ml-auto {
  margin-left: auto;
}

.input-search {
  border: 1px solid #E3E3E5;
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;
}

.row-lead-selected > td{
  background: #F2F2F2 !important;
}