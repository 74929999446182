.inbox-container {
    display: flex;
    width: 100%;
    overflow: hidden !important;

}

.inbox-sidebar {
    width: 275px;
    margin-top: 50px;
}

.inbox-search-container {
    background: #E9F1FB;
    width: 75%;
    padding: 12px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.inbox-email-header {
    background: white;
    display: flex;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-left: 15px;
}

.inbox-button {
    background: unset;
    border: unset;
    color: #444746;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: unset;
    font-size: 14px;
}

.inbox-button:hover {
    background-color: #F2F2F2;
}

.inbox-button:disabled {
    color: #B0B0B0;

}

.inbox-search-container:focus-within {
    background: white;
}

.inbox-search-bar {
    border: unset;
    background-color: unset;
    flex: 1
}

.inbox-search-bar:focus {
    outline: none;
}

.inbox-compose-button {
    padding: 16px 24px;
    border-radius: 12px;
    background-color: #C1E7FE;
    border: unset;
    font-size: 13px;
    font-weight: bold;
    padding-left: 16px;

}

.inbox-content {
    width: 100%;
    padding: 20px;
    overflow: hidden !important;

}

.inbox-email-checkbox {
    margin-right: 12px;
}

.inbox-email-star {
    margin-left: 6px;
    margin-right: 12px;
}

.inbox-email-checkbox>input {
    transform: scale(1.3);
}

.inbox-email-rows {
    display: flex;
    height: 546px;
    overflow-y: scroll;
}

.inbox-email-row {
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
}


.inbox-email-from {
    width: 200px;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    margin-right: 12px;
    text-decoration: none;
    color: #202124;
}

.inbox-email-subject {
    flex: 1;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    margin-right: 12px;
    text-decoration: none;
    color: #202124;
}


.inbox-email-body {
    flex: 1;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

.inbox-email-date {
    font-size: 13px;
}

.inbox-email-row:hover>.inbox-email-date {
    display: none;
}

.inbox-email-row:hover>.inbox-hide-actions {
    display: block;
}

.inbox-hide-actions {
    display: none;
}

.inbox-hide-actions>button {
    background-color: transparent !important;
    border: unset;
    border-radius: 50%;
    width: 24px;
    height: 20px;
    font-size: 14px;
    margin-left: 5px;
    margin-right: 5px;
}

.inbox-button-transparent {
    background-color: transparent !important;
    border: unset;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    font-size: 14px;
    margin-left: 5px;
    margin-right: 5px;
}

.inbox-hide-actions>button:hover,
.inbox-button-transparent:hover {
    background-color: #e2dfdf !important;
}

.inbox-profile-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.inbox-detail-container {
    background: white;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 12px;
    height: 85vh;
    overflow-y: scroll;
}

.inbox-detail-subject {
    font-size: 22px;
    padding-left: 56px;
    padding-top: 12px;
}

.inbox-reply-button {
    border-radius: 18px;
    height: 36px;
    background: none;
    color: #444746;
    border: 1px solid #747775;
    padding-left: 12px;
    padding-right: 12px;
    font-size: .875rem;
    min-width: 125px;
    font-weight: 500;
    margin-right: 8px;
}

.inbox-detail-buttons {
    display: flex;
    border-bottom: 1px solid #E0E4E8;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.inbox-compose-recipient {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.inbox-compose-recipient>div {
    flex: 1;
}

.inbox-compose-container {
    position: absolute;
    bottom: 0;
    height: 450px;
    right: 50px;
    background-color: white;
    width: 450px;
    z-index: 100;
    box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, .14), 0px 3px 14px 2px rgba(0, 0, 0, .12), 0px 5px 5px -3px rgba(0, 0, 0, .2);
    display: block;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.inbox-compose-header {
    background-color: #f2f6fc;
    font-size: 14px;
    font-weight: bold;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.inbox-compose-header>button {
    border: 0;
    background-color: unset;
}

.inbox-compose-header>button:hover {
    background-color: #E4E9EC;
}




.inbox-compose-input {
    border: unset;
    margin-top: 7px;
    margin-bottom: 7px;
    font-size: 14px;
    width: 100%;
}

.inbox-compose-input:active,
.inbox-compose-input:focus {
    border: unset;
    outline: none;
}

.inbox-compose-content {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    height: 88%;
    position: relative;
}

.inbox-compose-send-button {
    background: #0b57d0;
    color: white;
    border-radius: 18px;
    padding-left: 20px;
    padding-right: 30px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 16px;
    border: none;
}

.inbox-compose-body {
    flex: 1;
    font-size: 14px;
    margin-top: 8px;
    overflow-y: scroll;
}

.inbox-reply-container {
    font-size: 14px;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 2px 6px 2px rgba(60, 64, 67, 0.149);
    border-radius: 16px;
    min-height: 200px;
    padding: 10px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
}

.inbox-reply-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

}

.inbox-compose-reply {
    margin-top: 10px;
    margin-bottom: 14px;
    flex: 1;
}

.inbox-compose-body:focus,
.inbox-compose-reply:focus {
    outline: none;
}

.inbox-email-content {
    font-size: 14px;
    margin-bottom: 10px;
}

.inbox-compose-send-button:hover {
    background: #297be6;
}

.inbox-compose-send-button:disabled {
    background: #B0B0B0;
    cursor: not-allowed;
}

.badge-inbox {
    background-color: #3498db;
}

.inbox-reply-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.inbox-reply-header>div {
    flex: 1;
}